import {store} from '../app/store';
import {BASE_URL} from '../constant';
import {Difficulty} from "../custom";
import {showNotification} from "../store/headerSlice";
import {Language} from '../store/languageSlice';
import i18n from '../i18nf/i18n'
import {PartOfSpeech, WordExampleTranslation, WordTranslation} from '../store/training/trainingTypes';
import {
    addAlreadyCreated,
    addWord,
    AddWordType,
    TranslationJson,
    VocabularyType
} from "../store/vocabulary/vocabularySlice";
import {axios_delete, axios_get, axios_post, fetch_get, getRandomUUID} from "./globalUtils";

export type RequestType<T> = {
    data: T,
    config?: {}
}

export type PossibleTranslationType = {
    word: string;
    targetLanguage: string;
    sourceLanguage: string;
    wordLimit: number;
    isAutoComplete: boolean;
}


export type PossibleTranslationResponse = {
    possibleTranslations: TranslationJson[];
}

export type AddWord = {
    tempId: string;
    word: string;
    vocabularyId: number;
    vocabularyGroupId: number;
    wordTranslation: {
        translation: string,
        pos: PartOfSpeech
    }
}

export type ResponseStatus = "ALREADY_CREATED" | "NEW" | "FAIL" | "EXISTS";

export type UserVocabularyResponseType = {
    tempWordId: string;
    status: ResponseStatus;
    userVocabularyId?: number;
    wordTranslation?: WordTranslation;
    wordExampleTranslation?: WordExampleTranslation;
    userId?: number;
    error?: string;
    vocabularyGroupIdList?: number[];
}

const state = store.getState();
const token = state.user.token;
const defaultConfig = {
    "Autorization": "Bearer " + token
}

export const distinctTranslations = (arr: TranslationJson[]) => {
    const visited: TranslationJson[] = [];

    for (let e of arr) {
        const find = visited.find(v => v.translation === e.translation)
        if (!find) {
            visited.push(e);
        }
    }

    return visited;
}

export const distinct = (arr: string[]): string[] => {
    const map = new Map<string, string>();

    for (let s of arr) {
        if (!map.has(s.toLowerCase())) {
            map.set(s.toLowerCase(), s);
        }
    }
    return Array.from(map.values()).sort((a, b) => a.localeCompare(b));
}

export const fetchWord = async (wordId: number) => {
    return axios_get(`/vocabulary/getWord/${wordId}`);
}

export const fetchWordsByVocabulary = async (vocabularyId: number) => {
    if (!token) return null;

    return axios_get(`/vocabulary/getWords/${vocabularyId}`, defaultConfig)
}

export const fetchWordsByVocabularyNGroup = async (vocabularyId: number, vocabularyGroupId: number) => {
    if (!vocabularyGroupId || !token) return []

    return axios_get(`/vocabulary/getWords/${vocabularyId}?vocabularyGroupId=${vocabularyGroupId}`, defaultConfig)
}

export const fetchWordsByVocabularyGroupId = async (vocabularyGroupId: number) => {
    const state = store.getState()
    const token = state.user.token;

    return axios_get(`/vocabulary/getWords?vocabularyGroupId=${vocabularyGroupId}`, {
        "Authorization": "Bearer " + token
    })
}

export const fetchVocabularies = async () => {
    if (!token) return null;

    return axios_get(`/vocabulary/getAllVocabularies`, defaultConfig);
}

export const fetchVocabulariesWithFetch = async (authToken?: string) => {

    return (await fetch(
        `${BASE_URL}/vocabulary/getAllVocabularies`,
        {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${authToken ?? token}`
            }
        }
    )).json();
}

export const fetchSuggestedVocabularyGroupsWithFetch = async (learningLanguage: string, nativeLanguage: string, authToken?: string) => {
    return (await fetch(
        `${BASE_URL}/vocabularyGroup/getSuggestedVocabularyGroups?learningLanguage=${learningLanguage.toUpperCase()}&nativeLanguage=${nativeLanguage.toUpperCase()}`,
        {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${authToken ?? token}`
            }
        }
    )).json();
}

export const fetchSuggestedVocabularyGroups = async ({data, config}: RequestType<{
    learningLanguage: string,
    nativeLanguage: string
}>) => {
    const {learningLanguage, nativeLanguage} = data;

    return axios_get(`/vocabularyGroup/getSuggestedVocabularyGroups?learningLanguage=${learningLanguage.toUpperCase()}&nativeLanguage=${nativeLanguage}`, {...defaultConfig, ...config});
}
export const createVocabularyRequest = async (vocabulary: { learningLanguage: string, nativeLanguage: string }) => {
    return await axios_post(`/vocabulary/create`, vocabulary);
}

export const createVocabularyGroup = async (vocabularyGroup: { vocabularyId: number, name: string }) => {
    return await axios_post(`/vocabularyGroup/create`, vocabularyGroup);
}

export const deleteVocabularyGroup = async (vocabularyId: number) => {
    return axios_delete(`/vocabularyGroup/delete/${vocabularyId}`);
}

export const getPossibleTranslations = async (wordInformation: PossibleTranslationType): Promise<PossibleTranslationResponse> => {
    return await axios_post(`/vocabulary/getPossibleTranslations`, wordInformation);
}

export const addWordToVocabulary = async (addWord: AddWord): Promise<UserVocabularyResponseType> => {
    return await axios_post(`/vocabulary/add-word`, addWord);
}

export const deleteWordFromVocabulary = async (wordId?: number | string, vocabularyGroupId?: number) => {
    return await axios_delete(`/vocabulary/delete-word/${wordId ?? "-1"}?vocabularyGroupId=${vocabularyGroupId}`);
}

export const getAutocomplete = async (str: string, sourceLanguage: Language, targetLanguage: Language, authToken?: string) => {
    return await fetch_get(`/vocabulary/autocomplete?str=${str}&sourceLanguage=${sourceLanguage}&targetLanguage=${targetLanguage}`, authToken ?? token)
}

export const deleteVocabulary = async (vocabularyId: number) => {
    return await axios_delete(`/vocabulary/delete-vocabulary/${vocabularyId}`);
}

export const updateLastPickedVocabulary = async (vocabularyId: number) => {
    return await axios_get(`/user/updateLastPickedVocabulary/${vocabularyId}`)
}

export const createFromPredefinedVocabularyGroup = async (vocabularyId: number, vocabularyGroupId: number) => {
    return await axios_post(`/vocabularyGroup/createFromPredefined`, {vocabularyId, vocabularyGroupId});
}

export const addToAnotherVocabularyGroup = async (vocabularyGroupId: number, userVocabularyId: number) => {
    return await axios_get(`/vocabularyGroup/addToAnotherVocabularyGroup?vocabularyGroupToId=${vocabularyGroupId}&userVocabularyId=${userVocabularyId}`)
}

export const changeTranslation = async (userVocabularyId: number, translation: string, pos: PartOfSpeech, vocabularyGroupId: number) => {
    return await axios_post("/vocabulary/changeTranslation", {
        id: userVocabularyId,
        translation,
        partOfSpeech: pos.toUpperCase(),
        vocabularyGroupId,
    });
}

export const regenerateExamples = async (userVocabularyId: number, vocabularyGroupId: number, difficulty: Difficulty) => {
    return await axios_post(`/vocabulary/regenerateExamples`, {
        userVocabularyId,
        vocabularyGroupId,
        difficulty
    });
}

export const handleTranslationPickUtil = async (
    addWordField: string,
    vocabulary: VocabularyType,
    translationJson: TranslationJson,
    vocabularyGroupId: string) => {

    if (!addWordField) return;

    if (translationJson.pos === "PROPN") {
        translationJson.pos = "NOUN";
    }

    const tempId = getRandomUUID();
    const vGid = +(vocabularyGroupId ?? "-1");

    const addWordData: AddWord = {
        tempId,
        word: addWordField,
        vocabularyId: vocabulary.id,
        vocabularyGroupId: vGid,
        wordTranslation: translationJson,
    };

    const res = await addWordToVocabulary(addWordData);

    switch (res.status) {
        case "EXISTS": {
            store.dispatch(
                showNotification({
                    message: "Word already exists in vocabulary",
                    status: 0,
                })
            );
            break;
        }
        case "FAIL": {
            store.dispatch(
                showNotification({
                    message: "Failed to add word",
                    status: 1,
                })
            );
            break;
        }
        case "ALREADY_CREATED": {
            store.dispatch(addAlreadyCreated({
                wordListWord: res,
                vocabularyId: vocabulary.id,
                vocabularyGroupId: vGid
            }));
            break;
        }
        case "NEW": {

            const data: AddWordType = {
                tempWordId: tempId,
                word: addWordField,
                vocabularyId: vocabulary.id,
                translation: translationJson.translation,
                vocabularyGroupId: vGid,
                loading: true
            };

            store.dispatch(addWord(data));
        }
    }
}

export const processVocabularies = async (authToken: string) => {
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${authToken}`);

    return await fetch(`${BASE_URL}/vocabulary/getAllVocabularies`, {
        headers: headers
    }).then(res => {
        if (res.status === 200) return res.json()
        else if (res.status === 401) {
            if (window.location.href.includes("app"))
                window.location.href = `/${i18n.language}/login`;
            return null;
        }
    }).catch(_ => {
        if (window.location.href.includes("app") && !window.location.href.includes("login")
            && !window.location.href.includes("register")) {
            window.location.href = `${i18n.language}/login`;
        }
    });
}


export function mapDefaultVocabularyName(lang: string): string {
    switch (lang) {
        case "en":
            return "vocabulary";
        case "de":
            return "Wortschatz";
        case "ru":
            return "словарь";
        case "es":
            return "vocabulario";
        default:
            return "VOCABULARY";
    }
}