import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    selectCurrentlyTrainedWords,
    selectFinalSequenceIndex,
    selectFinalTrainingSequence
} from "../../store/training/trainigSelector";
import {
    addToAudioArray,
    addToCompleteEmptySpaces,
    addToPhraseConstructionArray,
    addToSentenceAudioArray,
    addToSentenceTypeArray,
    addToTranslationArray,
    incrementTotalExercisesCount,
    setCurrentlyTrainedWords,
    setFinalTrainingSequence
} from "../../store/training/trainingSlice";

import i18n from "../../i18nf/i18n";
import {TrainingExampleWithIndex, TrainingI} from "../../store/training/trainingTypes";
import {articles, fetchTraining, removeSpecialCharacters} from "../../utils/trainingUtils";
import TrainingComplete from "./TrainingComplete";
import SentenceAudio from "./trainingTypes/hard/SentenceAudio";
import SentenceType from "./trainingTypes/hard/SentenceType";
import AudioTraining from "./trainingTypes/standard/AudioTraining";
import CompleteEmptySpacesTraining from "./trainingTypes/standard/CompleteEmptySpacesTraining";
import PhraseConstruction from './trainingTypes/standard/PhraseConstruction';
import TranslationTraining from "./trainingTypes/standard/TranslationTraining";
import {useNavigate, useSearchParams} from "react-router-dom";
import {selectCurrentlySelected, selectVocabularyGroup} from "../../store/vocabulary/vocabularySelector";
import SuspenseContent from "../../containers/SuspenseContent";
import {Language} from "../../store/languageSlice";

export const WordsTranslation = ({word, value}: { word: string, value?: Array<string> }) => {
    return (
        <span className="dropdown dropdown-hover">
            <input type="button" className="m-1">{word}</input>
            <ul className="dropdown-content z-[1] menu p-2 shadow bg-base-100  rounded-box w-24 md:w-52 ">
                {value?.map(e => (
                    <li className="text-xs" key={e}>{e}</li>
                ))}
            </ul>
        </span>
    );
}

export interface TrainingTypesProps {
    elem: TrainingExampleWithIndex;
    targetWord: TrainingI;
}

export const constructWordsTranslationSentence = (sentence: string, wordsTranslation: Map<string, string[]>, language ?: Language, identifiedWord?: string) => {
    if (!sentence) return [];
    const arr = [];
    let count = Math.random() * 1000 - 234234;

    for (let word of sentence.split(" ")) {
        if (word === "?") continue;

        let searchedKey: string = "";
        if (word.length >= 2) {
            for (let [key,] of wordsTranslation) {
                if (removeSpecialCharacters(key) === removeSpecialCharacters(word)) {
                    searchedKey = key;
                    break;
                }
            }
        }
        const value = wordsTranslation.get(searchedKey);

        if (language && identifiedWord) {
            const toRemoveParts = articles.get(language) ?? [];
            if (identifiedWord.trim().toLowerCase().startsWith(word.trim().toLowerCase()) && toRemoveParts.includes(word)) {
                continue;
            }
        }

        arr.push(
            <span className="relative inline-block group" key={word + count++}>
                <button className="m-1">{word}</button>
                {value &&
                    <ul className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 z-[1] menu p-2 shadow bg-slate-50 rounded-lg w-24 md:w-52 hidden group-hover:block">
                        {value.map((e, index) => {
                            return (<li className="text-xs" key={e + index}>{e}</li>)
                        })}
                    </ul>
                }
            </span>
        )
    }
    return arr;
}

const Training = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const vocabulary = useSelector(selectCurrentlySelected);
    const vocabularyGroup = useSelector(selectVocabularyGroup);
    const currentlyTrainedWords = useSelector(selectCurrentlyTrainedWords);
    const finalSequence = useSelector(selectFinalTrainingSequence);
    const finalSequenceIndex = useSelector(selectFinalSequenceIndex);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchParams, ] = useSearchParams();
    const vocabularyGroupId: number = Number.parseInt(searchParams.get('vocabularyGroupId') ?? "0");


    useEffect(() => {
        const fetch = async () => {
            setLoading(true)
            const training = await fetchTraining({
                vocabularyId: vocabulary.id,
                vocabularyGroupId: vocabularyGroupId || vocabularyGroup.groupId
            });

            if (!training.trainingExamples || training.trainingExamples.length === 0) {
                navigate(`/${i18n.language}/app/vocabularies/${vocabulary.id}/${vocabularyGroupId || vocabularyGroup.groupId}`)
                return;
            }

            dispatch(setCurrentlyTrainedWords(training))
            setLoading(false);
        }

        if (finalSequence.length > 0 || finalSequenceIndex > 0) return;

        fetch().then();
    }, [dispatch, finalSequence.length, finalSequenceIndex, navigate, vocabulary.id, vocabularyGroup.groupId, vocabularyGroupId])

    console.log('currentWords', currentlyTrainedWords);

    const constructSequence = useCallback(() => {
        for (let index in currentlyTrainedWords.trainingExamples) {
            const currIndex = Number.parseInt(index);
            const current = currentlyTrainedWords.trainingExamples[currIndex];
            for (let trainingExampleIndex in current.trainingExampleList) {
                let trainingExample = new TrainingExampleWithIndex(currIndex,
                    current.trainingExampleList[trainingExampleIndex],
                    current.trainingId);

                console.log('trainingType ', trainingExample.trainingExample.trainingType);
                switch (trainingExample.trainingExample.trainingType) {
                    case "TRANSLATION":
                        dispatch(addToTranslationArray(trainingExample))
                        break;
                    case "PHRASE_CONSTRUCTION":
                    case "PHRASE_CONSTRUCTION_REVERSED":
                        dispatch(addToPhraseConstructionArray(trainingExample))
                        break;
                    case "AUDIO":
                        dispatch(addToAudioArray(trainingExample))
                        break;
                    case "COMPLETE_EMPTY_SPACES":
                        dispatch(addToCompleteEmptySpaces(trainingExample))
                        break;
                    case "SENTENCE_AUDIO":
                        dispatch(addToSentenceAudioArray(trainingExample))
                        break;
                    case "SENTENCE_TYPE":
                        dispatch(addToSentenceTypeArray(trainingExample))
                        break;
                }
            }
        }
        dispatch(setFinalTrainingSequence())
    }, [currentlyTrainedWords, dispatch])


    useEffect(() => {
        constructSequence()
    }, [constructSequence])

    useEffect(() => {
        dispatch(incrementTotalExercisesCount())
    }, [dispatch, finalSequenceIndex])

    const startSequence = (): JSX.Element | null => {
        if (finalSequence.length === 0) return null;
        else if (finalSequenceIndex >= finalSequence.length) {
            return <TrainingComplete trainingSessionId={currentlyTrainedWords.trainingSessionId}
                                     finalSequence={finalSequence}/>;
        }

        const elem = finalSequence[finalSequenceIndex];
        const targetWord = currentlyTrainedWords.trainingExamples[elem.index];

        switch (elem.trainingExample.trainingType) {
            case "TRANSLATION":
                return <TranslationTraining targetWord={targetWord} elem={elem}/>
            case "PHRASE_CONSTRUCTION":
            case "PHRASE_CONSTRUCTION_REVERSED":
                return <PhraseConstruction elem={elem} targetWord={targetWord}/>
            case "AUDIO":
                return <AudioTraining targetWord={targetWord} elem={elem}/>
            case "COMPLETE_EMPTY_SPACES":
                return <CompleteEmptySpacesTraining elem={elem} targetWord={targetWord}/>
            case "SENTENCE_AUDIO":
                return <SentenceAudio targetWord={targetWord} elem={elem}/>
            case "SENTENCE_TYPE":
                return <SentenceType elem={elem} targetWord={targetWord}/>
            default:
                return null;
        }
    }

    const elem = startSequence();

    return loading
        ? (<SuspenseContent size={undefined}/>)
        : (
            <div className="bg-white">
                {elem}
            </div>
        )
}

export default Training;