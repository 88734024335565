import {ChangeEvent, forwardRef} from 'react';

export type CompleteEmptySpacesInputProps = {
    width: string;
    value?: string;
    trainingExampleId: number;
    defaultValue?: string;
    onChangeEvent: (e: ChangeEvent<HTMLInputElement>) => void;
    correct: boolean;
}

export type TrainingInputProps = {
    correctValue: string;
} & CompleteEmptySpacesInputProps;

const TrainingInput = forwardRef<HTMLInputElement, TrainingInputProps>(({
                                                                            width,
                                                                            value,
                                                                            trainingExampleId,
                                                                            defaultValue,
                                                                            onChangeEvent,
                                                                            correct,
                                                                            correctValue
                                                                        }, ref) => {

    return (correct || value) ? (
        <TrainingInputComponent width={width} trainingExampleId={trainingExampleId}
                                onChangeEvent={onChangeEvent} correct={correct}
                                defaultValue={defaultValue} ref={ref} value={correct ? value : correctValue}
        />
    ) : (
        <TrainingInputComponent width={width} trainingExampleId={trainingExampleId}
                                onChangeEvent={onChangeEvent} correct={correct}
                                defaultValue={defaultValue} ref={ref}
        />
    )
});

const TrainingInputComponent = forwardRef<HTMLInputElement, CompleteEmptySpacesInputProps>(({
                                                                                                width,
                                                                                                value,
                                                                                                trainingExampleId,
                                                                                                defaultValue,
                                                                                                onChangeEvent,
                                                                                                correct,
                                                                                            }, ref) => {
    const className = `training-input my-3 ml-2  
    ${correct ? "bg-green-50 border-2 border-green-400"
        : "bg-slate-50 border-2 border-blue-200"}`;

    return value ? (
        <input
            tabIndex={0}
            autoFocus
            readOnly={correct}
            style={{width: width}}
            ref={ref}
            value={value ?? defaultValue}
            key={trainingExampleId}
            onChange={onChangeEvent}
            autoComplete="off" autoCorrect="off" spellCheck="false" autoCapitalize="off"
            className={className}
        />
    ) : <input
        tabIndex={0}
        autoFocus
        readOnly={correct}
        style={{width: width}}
        ref={ref}
        defaultValue={defaultValue}
        key={trainingExampleId}
        onChange={onChangeEvent}
        autoComplete="off" autoCorrect="off" spellCheck="false" autoCapitalize="off"
        className={className}
    />
});

export default TrainingInput