import React, {ChangeEvent, memo, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch} from "react-redux";
import {SOUND_TRAINING} from "../../../../constant";
import {
    incrementFinalSequenceIndex,
    incrementHintsNumber,
    incrementMistakeNumber
} from '../../../../store/training/trainingSlice';
import {getBlockWidth} from '../../../../utils/globalUtils';
import {
    compareCollapsed,
    constructMap,
    hintTriggerEvent,
    playSound,
    removeArticles,
    removeSpecialCharacters,
    revealAnswer
} from '../../../../utils/trainingUtils';
import {constructWordsTranslationSentence, TrainingTypesProps} from '../../Training';
import TrainingBlock from '../TrainingBlock';
import TrainingImage from '../TrainingImage';
import TrainingInput from './TrainingInput';
import {motion} from "framer-motion";
import {Language} from "../../../../store/languageSlice";

const CompleteEmptySpacesTraining = memo(({elem, targetWord}: TrainingTypesProps) => {
    const dispatch = useDispatch();
    const wordFrom = targetWord.wordTranslation.wordFrom;
    const {word, imageUrl} = wordFrom;
    const identifiedWord = elem.trainingExample.identifiedWord;
    const [input, setInput] = useState<string>("");
    const [firstSentencePart, setFirstSentencePart] = useState<JSX.Element[]>();
    const [secondSentencePart, setSecondSentencePart] = useState<JSX.Element[]>();
    const [image, setImage] = useState<string>(imageUrl);
    const [correct, setCorrect] = useState<boolean>();
    const [hint, setHint] = useState<boolean>(false);
    const [skipped, setSkipped] = useState<boolean>(false);

    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        setCorrect(false)
        setSkipped(false)
        setHint(false)
        setInput("")
    }, [elem.trainingExample.sentence])

    const onChangeEvent = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setInput(event.target.value)
    }

    const hintClickTrigger = () => {
        const temp = removeArticles(elem.trainingExample.identifiedWord,
            targetWord.wordTranslation.wordFrom.language as Language)
            .slice(0, 2);
        dispatch(incrementHintsNumber({index: elem.index, trainingType: elem.trainingExample.trainingType}))
        hintTriggerEvent(setInput, temp, undefined, false)
        setTrainingInput(
            <TrainingInput
                key={`${Math.random()}:${elem.trainingExample.sentence}`}
                width={getBlockWidth(targetWord.wordTranslation.wordFrom.word)}
                defaultValue={temp}
                onChangeEvent={onChangeEvent}
                correct={correct ?? false}
                trainingExampleId={elem.trainingExample.id}
                correctValue={elem.trainingExample.identifiedWord}
            />)
        setHint(true)
    }

    const showCorrectAnswer = useCallback(() => {
        if (elem.trainingExample.identifiedWord === "") setInput(identifiedWord)
        else setInput(elem.trainingExample.identifiedWord)

        setCorrect(true);
        setSkipped(true);
        revealAnswer(setInput, identifiedWord, elem);
        dispatch(incrementMistakeNumber({index: elem.index, trainingType: elem.trainingExample.trainingType}));
    }, [dispatch, elem, identifiedWord])

    const handleSkipClick = useCallback(() => {
        if (correct && !skipped) return;
        else if (correct) {
            setInput("")
            setCorrect(false)
            setSkipped(false)
            setHint(false)
            setFirstSentencePart([]);
            dispatch(incrementFinalSequenceIndex())
        } else {
            showCorrectAnswer()
        }
    }, [correct, dispatch, showCorrectAnswer, skipped])

    useEffect(() => {
        console.log("rending complete empty spaces component -----------------------------------------------------------------")

        const temp = removeSpecialCharacters(elem.trainingExample.formattedString);
        const constructTrainingSentence = () => {
            const tempArr = temp.split("###");

            const wordsTranslationMapFirst = constructWordsTranslationSentence(
                tempArr[0],
                constructMap(elem.trainingExample?.wordsTranslation || {}),
                wordFrom.language as Language,
                identifiedWord
            );
            console.log("🚀 ~ constructTrainingSentence ~ tempArr:", tempArr[0]);
            const wordsTranslationMapSecond = constructWordsTranslationSentence(
                tempArr[1],
                constructMap(elem.trainingExample?.wordsTranslation || {})
            );
            console.log("🚀 ~ constructTrainingSentence ~ tempArr:", tempArr[1]);

            wordsTranslationMapFirst.concat(wordsTranslationMapSecond);

            setFirstSentencePart(wordsTranslationMapFirst);
            setSecondSentencePart(wordsTranslationMapSecond);
        };

        constructTrainingSentence();
        setImage(imageUrl + '');
    }, [correct, elem.trainingExample, elem.trainingExample.formattedString, elem.trainingExample?.wordsTranslation, elem.trainingExample.identifiedWord, identifiedWord.length, imageUrl, input, identifiedWord]);

    const completeTraining = useCallback(() => {
        setCorrect(true)
        playSound(SOUND_TRAINING)
            .then(message => console.log(message))
            .catch(error => console.error("Error:", error));

        setTimeout(() => {
            playSound(elem.trainingExample.soundUrl).then(() => console.log("play sound"))
        }, 400);

        if (!skipped) {
            // window.removeEventListener("keydown", handleKeyPress)
            setInput("");
            setTimeout(() => {
                setFirstSentencePart([]);
                setInput("")
                setHint(false)
                setCorrect(false)
                setTimeout(() => {
                    dispatch(incrementFinalSequenceIndex())
                }, 100)
            }, 3400)
        }
    }, [dispatch, elem.trainingExample.soundUrl, skipped])

    useEffect(() => {
        debugger
        if (correct) {
            setTrainingInput(
                <TrainingInput correctValue={elem.trainingExample.identifiedWord}
                               key={`${elem.trainingExample.id}:${elem.trainingExample.sentence}`}
                               width={getBlockWidth(identifiedWord)}
                               value={correct ? elem.trainingExample.identifiedWord : input ?? ''}
                               trainingExampleId={elem.trainingExample.id} onChangeEvent={onChangeEvent}
                               correct={correct ?? false} ref={inputRef}/>
            )
        } else {
            setTrainingInput(
                <TrainingInput correctValue={elem.trainingExample.identifiedWord}
                               key={`${elem.trainingExample.id}:${elem.trainingExample.sentence}`}
                               width={getBlockWidth(identifiedWord)}
                               value={''}
                               trainingExampleId={elem.trainingExample.id} onChangeEvent={onChangeEvent}
                               correct={correct ?? false} ref={inputRef}/>
            )
        }

    }, [correct, elem.trainingExample.id, elem.trainingExample.identifiedWord, elem.trainingExample.sentence, identifiedWord]);

    useEffect(() => {
        if (compareCollapsed(input || "", elem.trainingExample.identifiedWord)) {
            completeTraining()
        }

    }, [input, dispatch, elem.trainingExample.identifiedWord, completeTraining])

    const [trainingInput, setTrainingInput] = useState<any>(<TrainingInput
        correctValue={elem.trainingExample.identifiedWord}
        key={`${elem.trainingExample.id}:${elem.trainingExample.sentence}`}
        width={getBlockWidth(identifiedWord)}
        value={''}
        trainingExampleId={elem.trainingExample.id} onChangeEvent={onChangeEvent}
        correct={correct ?? false} ref={inputRef}/>)

    return (
        <div className="h-full content w-full max-w-[64rem] m-auto">
            <TrainingBlock isCorrect={correct || false} isSkipped={skipped || false} setInput={setInput}
                           showHint={hintClickTrigger} isHint={hint}
                           showCorrectAnswer={showCorrectAnswer} handleSkipClick={handleSkipClick}>
                <TrainingImage src={image}/>
                <motion.div
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{duration: 0.5}}
                    className="flex flex-col items-center text-center justify-center w-full">
                    <span className='text-3xl font-semibold mt-5 mb-2 max-md:text-2xl '>
                        {firstSentencePart}
                        {trainingInput}
                        {secondSentencePart}
                    </span>
                    <span
                        className={`${hint || correct ? "visible animation" : "invisible"} text-3xl font-semibold m-1 max-md:text-2xl text-blue-600`}>
                        {elem.trainingExample.sentenceTranslation}
                    </span>
                </motion.div>
            </TrainingBlock>
        </div>
    )
});

export default CompleteEmptySpacesTraining;