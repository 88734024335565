export type PartOfSpeech =
    "NOUN" |
    "VERB" |
    "ADJECTIVE" |
    "OTHER" | "PROPN";

export type TrainingType =
    "PHRASE_CONSTRUCTION" | "PHRASE_CONSTRUCTION_REVERSED" |
    "COMPLETE_EMPTY_SPACES" |
    "AUDIO" | "SENTENCE_AUDIO" | "SENTENCE_TYPE" | "TRANSLATION";

export type WordStatus =
    "LEARNED" |
    "REPEAT" |
    "NEW" |
    "IN_PROCESS";

export type WordType =
    "WORD" | "SENTENCE" | "SPREAD_VERB" |
    "PHRASE" | "GERMAN_PHRASAL_VERB" | "PHRASAL_VERB";

export interface UserVocabulary {
    tempWordId?: string;
    userVocabularyId?: number;
    wordTranslation?: WordTranslation;
    wordExampleTranslation?: WordExampleTranslation;
    loading?: boolean;
    status?: WordStatus;
    vocabularyGroupId?: number;
    // used exclusively for new words that haven't been loaded yet
    createdAt: number;
}

export interface WordExampleTranslation {
    id?: number | string;
    example?: string;
    exampleTranslation?: string;
    soundUrl?: string;
    wordId?: number | string;
}

export interface WordDictionary {
    description: string;
    descriptionTranslation: string;
    synonyms: string[];
    conjugation: IConjugation;
}

export interface IConjugation {
    id: number | string;
    infinitive: string;
    conjugation: IMappings;
}

export interface IMappings {
    gender?: string;
    mappings: {};
}

export interface IWord {
    id: number | string;
    word: string;
    language: string;
    partOfSpeech: PartOfSpeech;
    imageUrl: string;
    soundUrl: string;
    wordDictionary: WordDictionary;
    wordType: WordType;
}

export interface WordTranslation {
    id?: number | string;
    wordFrom: IWord;
    wordTo: IWord;
}

export interface TrainingExample {
    id: number,
    sentence: string,
    sentenceTranslation: string,
    formattedString: string,
    wordsTranslation: {},
    soundUrl: string,
    trainingType: TrainingType,
    identifiedWord: string
}

export interface TrainingI {
    trainingId: number;
    wordTranslation: WordTranslation;
    trainingExampleList: TrainingExample[];
}

export class TrainingExampleWithIndex {
    constructor(public index: number, public trainingExample: TrainingExample, public trainingId: number,
                public hint: boolean = false, public skipped: boolean = false, public mistakeCount: number = 0) {
    }
}

export type TrainingState = {
    readonly currentlyTrainedWords: {
        trainingSessionId: number;
        trainingExamples: TrainingI[]
    },
    readonly translationArray: TrainingExampleWithIndex[],
    readonly audioArray: TrainingExampleWithIndex[],
    readonly phraseConstructionArray: TrainingExampleWithIndex[],
    readonly completeEmptySpacesArray: TrainingExampleWithIndex[],
    readonly finalTrainingSequence: TrainingExampleWithIndex[],
    readonly sentenceAudioArray: TrainingExampleWithIndex[],
    readonly sentenceTypeArray: TrainingExampleWithIndex[],
    readonly mistakesCount: number,
    readonly hintsCount: number,
    readonly finalSequenceIndex: number,
    readonly totalWordsOnTraining: number,
    readonly totalExercisesCount: number,
}